// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-atual-jsx": () => import("./../../../src/pages/atual.jsx" /* webpackChunkName: "component---src-pages-atual-jsx" */),
  "component---src-pages-cadastro-jsx": () => import("./../../../src/pages/cadastro.jsx" /* webpackChunkName: "component---src-pages-cadastro-jsx" */),
  "component---src-pages-contato-jsx": () => import("./../../../src/pages/contato.jsx" /* webpackChunkName: "component---src-pages-contato-jsx" */),
  "component---src-pages-historico-jsx": () => import("./../../../src/pages/historico.jsx" /* webpackChunkName: "component---src-pages-historico-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-regulamento-jsx": () => import("./../../../src/pages/regulamento.jsx" /* webpackChunkName: "component---src-pages-regulamento-jsx" */)
}

