import { extendTheme } from '@chakra-ui/react';

const overrides = {
  colors: {
    primary: 'hsl(199, 42%, 20%)',
    accent: 'hsl(9, 94%, 63%)',
  },
  components: {
    Link: {
      variants: {
        outline({ colorScheme }) {
          return {
            borderColor: `${colorScheme}.600`,
            borderWidth: '2px',
            color: `${colorScheme}.800`,
            display: 'block',
            padding: 3,
            textAlign: 'center',
            _hover: {
              background: `${colorScheme}.800`,
              borderColor: `${colorScheme}.800`,
              color: colorScheme === 'whiteAlpha' ? 'black' : 'white',
            },
          };
        },
      },
    },
  },
};

export default extendTheme(overrides);
